<template>

  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <div style="margin-top: 20px"></div>
    <el-form-item label="您的手机号" prop="mobileNumber" style="margin-right: 10px;">
      <el-input v-model="ruleForm.mobileNumber" placeholder="请输入您的手机号"></el-input>
    </el-form-item>
    <el-form-item label="您的邮箱" prop="email" style="margin-right: 10px;">
      <el-input v-model="ruleForm.email" placeholder="请输入您的邮箱"></el-input>
    </el-form-item>
    <el-form-item label="手机品牌" prop="brand" style="margin-right: 10px;">
      <el-input v-model="ruleForm.brand" placeholder="举例说明：华为"></el-input>
    </el-form-item>
    <el-form-item label="手机型号" prop="model" style="margin-right: 10px;">
      <el-input v-model="ruleForm.model" placeholder="举例说明：HUAWEI p50 Pro"></el-input>
    </el-form-item>
    <el-form-item label="安卓版本" prop="version" style="margin-right: 10px;">
      <el-input v-model="ruleForm.version" placeholder="举例说明：安卓 13"></el-input>
    </el-form-item>
    <el-form-item label="反馈类型" prop="region" style="margin-right: 10px;">
      <el-select v-model="ruleForm.region" placeholder="请选择反馈类型">
        <el-option label="分身应用闪退" value="1"></el-option>
        <el-option label="模拟位置失败" value="2"></el-option>
        <el-option label="其它问题" value="3"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="反馈内容" prop="desc" style="margin-right: 10px;">
      <el-input type="textarea" v-model="ruleForm.desc" :autosize="{ minRows: 5, maxRows: 4}" placeholder="举例说明：使用斗转多开模拟定位某某app后,进入应用闪退"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    data() {
      return {
        ruleForm: {
          mobileNumber: '',
          email: '',
          brand: '',
          model: '',
          version: '',
          region: '',
          desc: ''
        },
        rules: {
          mobileNumber: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur'}
          ],
          email: [
            { required: true, message: '请选择请输入邮箱', trigger: 'blur' },
            // eslint-disable-next-line no-useless-escape
            { pattern:/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: "请输入正确的邮箱", trigger: "blur"}
          ],
          brand: [
            { required: true, message: '请填写手机品牌', trigger: 'blur' }
          ],
          model: [
            { required: true, message: '请填写手机型号', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '请填写安卓版本', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择反馈类型', trigger: 'change' }
          ],
          desc: [
            { required: true, message: '请填写反馈内容', trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.axios({
              method:"post",
              url:"https://dzapi.txyapp.com/api/app/addFeedback",
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                mobileNumber: this.ruleForm.mobileNumber,
                email: this.ruleForm.email,
                brand: this.ruleForm.brand,
                model: this.ruleForm.model,
                version: this.ruleForm.version,
                region: this.ruleForm.region,
                desc: this.ruleForm.desc
              }
            }).then((response)=>{
              console.log(response);
              if(response.data.code == 200){
                this.$notify({
                  title: '成功',
                  message: '反馈成功',
                  type: 'success',
                  time: 1000
                });
                this.ruleForm.mobileNumber = '';
                this.ruleForm.email = '';
                this.ruleForm.brand = '';
                this.ruleForm.model = '';
                this.ruleForm.version = '';
                this.ruleForm.region = '';
                this.ruleForm.desc = '';
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>